@import '../../assets/sass/styles.scss';
.Main {
  background-color: $background-color;
  min-height: 100vh;

  .logo-container {
    img {
      max-width: 150px;
      position: absolute;
      left: 40px;
      top: 45px;
      z-index: 999;
    }
  }
  .banner-container {
    // filter: blur(4px) grayscale(40%);
    .banner-image {
      text-align: center;

      img {
        // max-height: 60vh;
        position: relative;
        width: 100vw;
      }
    }
  }
  .description-container {
    .campaign-description {
      font-size: 2rem;
      font-weight: 500;
      color: $primary;
      margin: 100px 0 40px 0;
      line-height: 1;
      text-align: center;
      //   max-width: 60%;
    }
    .campaign-period {
      text-align: center;
      font-size: 1.4rem;
    }
  }
  .nav-container {
    margin: 20px auto;
    text-align: center;
    .button {
      margin: 10px;
    }
  }
}

@media screen and (max-width: 769px) {
  .Main {
    min-height: 90vh;
    .logo-container {
      padding: 0px;
      img {
        left: 20px;
        top: 25px;
        max-width: 60px;
      }
    }
    .description-container {
      .campaign-description {
        font-size: 1.4rem;
        padding: 0 20px;
      }
      .campaign-period {
        font-size: 1rem;
      }
    }
    .nav-container {
      margin: 20px auto;
      text-align: center;
      .button {
        width: 90vw;
      }
    }
  }
}
