@import '../../assets/sass/styles.scss';

.Winners {
  width: 100vw;
  min-height: 100vh;
  // position: absolute;
  padding-top: 10vh;
  // overflow-y: auto;
  // overflow-x: hidden;
  .winners-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    margin-bottom: 5vh;
    h2 {
      color: $primary;
      font-size: 3rem;
      font-weight: 600;
    }
    span {
      color: $primary;
      font-size: 1.2rem;
      font-weight: 600;
    }
  }
  .winners-content {
    display: block;
    ul {
      padding: 0 5vw;
      text-align: center;
    }
    .prize-name {
      font-weight: 600;
      font-size: 1.2rem;
      margin-bottom: 5px;
      color: $primary;
      max-width: 300px;
    }
    .columns {
      margin: 0;
    }
  }
}

@media screen and (max-width: 769px) {
  .Winners {
    padding-top: 20vh;
    position: static;
    height: auto;
    .winners-header {
      text-align: center;
      span {
        max-width: 80vw;
      }
    }
    .winners-content {
      ul {
        padding-bottom: 20px;
      }
      .prize-name {
        margin: auto;
        text-align: center;
      }
    }
  }
}
