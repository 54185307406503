@charset "utf-8";
@import '../../assets/sass/styles.scss';

.nav-button {
  position: absolute;
  right: 2%;
  top: 25px;
  z-index: 15;
  font-size: 3rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
  &:focus {
    outline: solid rgba(146, 146, 146, 0.473) 1px;
  }
}

.nav-menu {
  position: fixed;
  z-index: 14;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-color: $background-color;
  left: 0;
  top: 0;
  margin: 0;
  ul {
    text-align: center;
  }
  &.columns {
    margin: 0;
  }
  a {
    color: $primary;
    font-size: 3.5rem;
    font-weight: 900;
  }
}

@media screen and (max-width: 769px) {
  .nav-button {
    right: 2%;
    padding: 0;
  }
  .nav-menu {
    a {
      font-size: 2.2rem;
    }
  }
}

.nav-slide-in-right {
  -webkit-animation: nav-slide-in-right 0.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: nav-slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes nav-slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes nav-slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.nav-slide-out-right {
  -webkit-animation: nav-slide-out-right 0.5s
    cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: nav-slide-out-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes nav-slide-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
}
@keyframes nav-slide-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
}
